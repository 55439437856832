.timeline{
  width: 100%;
}

$divider: 4px;
$line-color: #1976d2;

.tetel{
  position:relative;
  width: 100%;
  display: flex;
  padding: 4px 4px;
  opacity: 0.3;  
}
.tetel.active{
  opacity: 1;
}

.left{
  text-align: left;
}

.left::before {
  content: '';
  position: absolute;
  width: 2px;
  background-color: $line-color;
  left: $divider;
  top: 16px;
  bottom: 16px;
}

.left::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: $line-color;
  left: $divider;
  top: 16px;
  bottom: calc(100% - 20px);
}

.hasprev .left::before {
  top: calc(100% + 20px);
}

.hasnext .left::after {
  bottom: -9px;
  background-color: $line-color;
}


.right::after{
  top: 8px;
  left: $divider - 5px  ;
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 3px solid #9c27b0;
  background: #fff;
}

.right{
  margin-left: 24px;
}

.primary{
  font-weight: 700;
  width: 56px;
  display: inline-block;
}
.datum{
  color: rgba(0, 0, 0, 0.6);
  padding-right: 4px;  
}
.nap{
  display:inline-block;
  width: 36px;
}
.ora{
  font-weight: 700;
  display:inline-block;
  width: 48px;
}